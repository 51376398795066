import React from "react"
import {Link} from 'gatsby'
import Layout from "../gatsby-theme-blog/components/layout"
import Footer from "../custom-components/footer"
export default () => {
  return (
    <Layout>
      <div className="md:max-w-screen-md mx-auto my-16 leading-normal px-2 container" >

    
      <h1 className="my-2 text-xl">Privacy Policy</h1>
      <p>
        Geekynut.com websites refer to site hosted on Geekynut.com. This privacy
        policy describes how Geekynut.com uses and protects any information that
        you give us. We are committed to ensuring that your privacy is
        protected. If you provide us with personal information through
        Geekynut.com, you can be assured that it will only be used in accordance
        with this privacy statement.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Website Visitors</h2>
      <p>
        Like most website operators, Geekynut.com collects
        non-personally-identifying information of the sort that web browsers and
        servers typically make available, such as the browser type, language
        preference, referring site, and the date and time of each visitor
        request. Geekynut.com’s purpose in collecting non-personally identifying
        information is to better understand how Geekynut.com’s visitors use its
        website. From time to time, Geekynut.com may release
        non-personally-identifying information in the aggregate, e.g., by
        publishing a report on trends in the usage of its website.
      </p>
      Geekynut.com also collects potentially personally-identifying information
      like Internet Protocol (IP) addresses. Geekynut.com does not use IP
      addresses to identify its visitors, however, and does not disclose such
      information, other than under the same circumstances that it uses and
      discloses personally-identifying information, as described below.
      <h3 className="mt-3 mb-2">Gathering of Personally-Identifying Information</h3>
      <p>
        Certain visitors to Geekynut.com choose to interact with Geekynut.com in
        ways that require Geekynut.com to gather personally-identifying
        information. The amount and type of information that Geekynut.com
        gathers depends on the nature of the interaction. For example, we may
        ask visitors who use our blog to provide a an email address.
      </p>
      <p>
        Geekynut.com collects such information only insofar as is necessary or
        appropriate to fulfill the purpose of the visitor’s interaction with
        Geekynut.com. Geekynut.com does not disclose personally-identifying
        information other than as described below. And visitors can always
        refuse to supply personally-identifying information.
      </p>
      <p>
        All of the information that is collected on Geekynut.com will be handled
        in accordance with GDPR legislation.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Protection of Certain Personally-Identifying Information</h2>
      <p>
        Geekynut.com discloses potentially personally-identifying and
        personally-identifying information only to those of project
        administrators, employees, and contractorss that need to know that
        information in order to process it on Geekynut.com’s behalf or to
        provide services available through Geekynut.com, and that have agreed
        not to disclose it to others. Some of those employees, contractors and
        affiliated organizations may be located outside of your home country; by
        using Geekynut.com, you consent to the transfer of such information to
        them.
      </p>
      <p>
        Geekynut.com will not rent or sell potentially personally-identifying
        and personally-identifying information to anyone. Other than to project
        administrators, employees, and contractors as described above,
        Geekynut.com discloses potentially personally-identifying and
        personally-identifying information only when required to do so by law,
        if you give permission to have your information shared, or when
        Geekynut.com believes in good faith that disclosure is reasonably
        necessary to protect the property or rights of Geekynut.com, third
        parties, or the public at large.
      </p>
      <p>
        If you are a registered user of a Geekynut.com website and have supplied
        your email address, Geekynut.com may occasionally send you an email to
        tell you about new articles, apps, solicit your feedback, or just keep
        you up to date with what’s going on with Geekynut.com and our products.
        We primarily use our blog to communicate this type of information, so we
        expect to keep this type of email to a minimum.
      </p>
      <p>
        If you send us a request (for example via a support email or via one of
        our feedback mechanisms), we reserve the right to publish it in order to
        help us clarify or respond to your request or to help us support other
        users. Geekynut.com takes all measures reasonably necessary to protect
        against the unauthorized access, use, alteration, or destruction of
        potentially personally-identifying and personally-identifying
        information.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Use of personal information</h2>
      <p>
        We use the information you provide send you newsletters, use certain
        other services, or participate in the Geekynut project in any other way.
      </p>
      <p>
        We will not sell or lease your personal information to third parties
        unless we have your permission or are required by law to do so.
      </p>
      {/* 
<p>

We would like to send you email marketing communication which may be of interest to you from time to time. If you have consented to marketing, you may opt out later.
</p> */}
      {/* 
<p>

You have a right at any time to stop us from contacting you for marketing purposes. If you no longer wish to be contacted for marketing purposes, please click on the unsubscribe link at the bottom of the email.
</p> */}
      <h2 className="mt-4 mb-2 text-lg">Legal grounds for processing personal information</h2>
      <p>We rely on one or more of the following processing conditions:</p>
      <p>
        our legitimate interests in the effective delivery of information and
        services to you; explicit consent that you have given; legal
        obligations. Access to data You have the right to request a copy of the
        information we hold about you. If you would like a copy of some or all
        your personal information, please follow the instructions at the end of
        this section.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Retention of personal information</h2>
      <p>
        We will retain your personal information on our systems only for as long
        as we need to. We keep contact information (such as mailing list
        information) until a user unsubscribes or requests that we delete that
        information from our systems. If you choose to unsubscribe from a
        mailing list, we may keep certain limited information about you so that
        we may honor your request.
      </p>
      <h3 className="mt-3 mb-2">
        If you would like to request deletion of your account and associated
        data, please follow these steps:
      </h3>
      <p>
        Send an email to nut@Geekynut.com with "Request Permanent Account
        Deletion" as object.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Rights in relation to your information</h2>
      <h2 className="mt-4 mb-2 text-lg">
        You may have certain rights under data protection law in relation to the
        personal information we hold about you. In particular, you may have a
        right to:
      </h2>
      <ul>
        <li>request a copy of personal information we hold about you;</li>
        <li>
          ask that we update the personal information we hold about you, or
          independently correct such personal information that you think is
          incorrect or incomplete;
        </li>
        ask that we delete personal information that we hold about you from live
        systems, or restrict the way in which we use such personal information;
        <li>
          object to our processing of your personal information; and/or withdraw
          your consent to our processing of your personal information.
        </li>
        <li>
          If you would like to exercise these rights or understand if these
          rights apply to you, please follow the instructions at the end of this
          Privacy statement.
        </li>
      </ul>
      <h2 className="mt-4 mb-2 text-lg">Third Party Links</h2>
      <p>
        Our website may contain links to other websites provided by third
        parties not under our control. When following a link and providing
        information to a 3rd-party website, please be aware that we are not
        responsible for the data provided to that third party. This privacy
        policy only applies to the websites listed at the beginning of this
        document, so when you visit other websites, even when you click on a
        link posted on Geekynut.com, you should read their own privacy policies.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Aggregated Statistics</h2>
      <p>
        Geekynut.com may collect statistics about the behavior of visitors to
        its websites. However, Geekynut.com does not disclose
        personally-identifying information other than as described in this
        policy.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Cookies</h2>
      <p>
        Additionally, information about how you use our website is collected
        automatically using “cookies”. Cookies are text files placed on your
        computer to collect standard internet log information and visitor
        behaviour information. This information is used to track visitor use of
        the website and to compile statistical reports on website activity.
        Please see our cookie policy for more information about what cookies are
        collected on{" "}
        <Link to="/cookies">geekynut.com/cookies.</Link>
      </p>
      <p></p>
      <p>
        Please see our cookie policy for more information about what cookies are
        collected on Geekynut.com.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Privacy Policy Changes</h2>
      <p>
        Although most changes are likely to be minor, Geekynut.com may change
        its Privacy Policy from time to time, and at Geekynut.com’s sole
        discretion. Geekynut.com encourages visitors to frequently check this
        page for any changes to its Privacy Policy. Your continued use of this
        site after any change in this Privacy Policy will constitute your
        acceptance of such change.
      </p>
      <h2 className="mt-4 mb-2 text-lg">Contact</h2>
      <p>
        Please contact us if you have any questions about our privacy policy or
        information we hold about you by emailing nut@geekynut.com
      </p>
    </div>

    <Footer />
</Layout>
  )
}
